import PropTypes from 'prop-types';
import cx from 'classnames';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';
import withTheme from 'core/components/theme';
import bindProps from 'core/components/bindProps';

import Link from 'core/components/Link';
import EmptyWrapper from 'core/components/EmptyWrapper';

import CardInfoLine from 'site/components/CardInfoLine';
import Label from 'site/components/Label';
import HighlightedText from 'site/components/HighlightedText';

import types from './types';
import styles from './index.styl';

const requiredPayloadImports = [
  'rubric',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'published_at',
];

const relationships = resolveRelationships(requiredPayloadImports, null);


function CardMainFadedHero(props) {
  const {
    content,
    size,
    type,
    theme,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    published_at: publishedAt,
  } = content.attributes;

  const {
    rubric,
  } = relationships(content);

  const {
    highlighted,
  } = types[type] || {};

  const TextWrapper = highlighted ? HighlightedText : EmptyWrapper;

  return (
    <Link
      to={link}
      type='secondary'
      className={cx(
        styles.card,
        styles[`_size_${size}`],
        highlighted && styles._highlighted,
      )}
    >
      <style jsx>{`
        .${styles.headline}
          font-family ${theme.fonts.display}
          color ${theme.colors.primary}
      `}</style>
      <Label rubric={rubric} />
      <div className={styles.texts}>
        <div className={styles.headlineWrapper}>
          <TextWrapper color={theme.colors.content}>
            <div className={styles.headline}>
              {listHeadline || headline}
            </div>
          </TextWrapper>
        </div>
        <TextWrapper color={theme.colors.content}>
          <div className={styles.infoLine}>
            <CardInfoLine
              publishedAt={publishedAt}
              color={theme.colors.primary}
            />
          </div>
        </TextWrapper>
      </div>
    </Link>
  );
}

CardMainFadedHero.propTypes = {
  /** Контент топика */
  content: modelPropTypes(topicAttributes),
  /** @ignore */
  theme: PropTypes.object.isRequired,
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1]),
  /** Размер карточки */
  size: PropTypes.oneOf(['m', 'l']),
};

CardMainFadedHero.defaultProps = {
  type: 0,
  size: 'm',
};

const Card = withTheme(CardMainFadedHero);

Card.displayName = 'CardMainFadedHero';
Card.requiredPayloadFields = requiredPayloadFields;

export const CardMainFadedHero1 = bindProps({ type: 1 })(Card);
export const CardMainFadedHeroL = bindProps({ size: 'l' })(Card);

export default Card;

export { CardMainFadedHero as StorybookComponent };
