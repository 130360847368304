import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { rawAuthorsQuery } from 'core/queries/authorsPage';
import footerQuery from 'site/queries/footer';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import AuthorsPage from 'core/components/AuthorsPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { Indent, PageIndent } from 'core/components/Wrappers';
import PageLoader from 'core/components/Loader/PageLoader';

import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';
import CardAuthor, { CardAuthorS } from 'site/cards/CardAuthor';
import { PageBoroda } from 'site/components/Wrappers';


function Authors({ isMobile, location, history }) {
  const [
    { data: rawAuthors, isLoading },
    { data: footerContent, isLoading: footerContentIsLoading },
  ] = useRequest({
    queries: [
      rawAuthorsQuery({ location, history }),
      footerQuery({ history }),
    ],
  });
  const feedProps = {
    card: isMobile ? CardAuthorS : CardAuthor,
    columns: 1,
    grid: false,
  };

  return (
    <PageBoroda content={footerContent} isLoading={footerContentIsLoading}>
      <PageIndent>
        <ColumnLayout rightColumn={<InnerSideColumn />}>
          <Indent top={40} />
          <h1>Авторы СФ</h1>
          <Indent top={40} />
          {isLoading && <PageLoader />}
          {!isLoading &&
            <AuthorsPage
              rawAuthors={rawAuthors}
              isLoading={isLoading}
              {...feedProps}
            />
          }
          <Boroda />
        </ColumnLayout>
      </PageIndent>
    </PageBoroda>
  );
}


Authors.propTypes = {
  isMobile: PropTypes.bool,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default withBreakpoint(Authors);
