import useRequest from 'core/hooks/useRequest';
import { aboutQuery } from 'core/queries/about';

import CoreAbout from 'core/components/About';

import footerQuery from 'site/queries/footer';

import { PageBoroda } from 'site/components/Wrappers';
import PropTypes from 'prop-types';


function AboutPage({ history }) {
  const { data: content, isLoading } = useRequest(aboutQuery({ history }));
  const { data: footerContent, isLoading: footerContentIsLoading } = useRequest(footerQuery({ history }));

  return (
    <PageBoroda content={footerContent} isLoading={footerContentIsLoading}>
      <CoreAbout isLoading={isLoading} content={content} />
    </PageBoroda>
  );
}

AboutPage.propTypes = {
  history: PropTypes.object,
};

export default AboutPage;
