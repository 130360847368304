import { Fragment } from 'react';
import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { topicQuery } from 'core/queries/topic';
import footerQuery from 'site/queries/footer';

import { resolve } from 'core/utils/env';
import { isGameCompare } from 'core/components/GameCompare/utils';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import { Mobile } from 'core/components/breakpoint';
import Divider from 'core/components/Divider';
import GameCompare from 'core/components/GameCompare';
import EmptyWrapper from 'core/components/EmptyWrapper';

import AdWrapper from 'core/components/Ad/AdWrapper';
import InfiniteTopic from 'core/components/InfiniteTopic';
import Topic from 'core/components/Topic';
import ColumnLayout from 'core/components/ColumnLayout';
import { checkScrollToSiteInTopic } from 'core/components/ScrollToSite';
import PremiumTopicHeader from 'core/components/PremiumTopicHeader';
import FullWidth from 'core/components/FullWidth';

import { TopBanner } from 'site/components/Ads/mobile';

import {
  Indent,
  PageBoroda,
  PageIndent,
  TopicTextWrap,
} from 'site/components/Wrappers';

import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';

import TopicContent from './TopicContent';
import TopicHeader from './TopicHeader';
import TopicFooter from './TopicFooter';
import TopicSkeleton from './Skeleton';

import { VERTICAL_INDENT,  VERTICAL_INDENT_MOBILE } from 'site/constants';

import { isEnciklopediya } from 'site/utils.js';
import { ENCIKLOPEDIYA_PATHNAME, ENCIKLOPEDIYA_SLUG } from 'site/constants.js';


function topicRenderer(topic, enhancedTopic, isMobile, isEnciklopediyaPage, isGameComparePage) {
  const {
    infinityIndex,
    content,
  } = enhancedTopic;

  const { is_premium: isPremiumTopic } = content.attributes;

  const isScrollToSite = checkScrollToSiteInTopic(enhancedTopic);

  const ContentWrapper = isMobile ? EmptyWrapper : TopicTextWrap;
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const ColumnWrapper = (isMobile || isPremiumTopic) ? EmptyWrapper : ColumnLayout;
  const isPremiumInInfinity = isPremiumTopic && infinityIndex !== 0;

  const hr = (
    <FullWidth>
      <Indent
        top={isMobile ? 15 : 60}
        bottom={isMobile ? 15 : 30}
      >
        <Divider />
      </Indent>
    </FullWidth>
  );

  return (
    <PageIndent>
      {(infinityIndex > 0 && !isPremiumTopic) && (
        <Mobile>
          <AdWrapper bottom={vertical}>
            <TopBanner lazy />
          </AdWrapper>
        </Mobile>
      )}
      {(!isEnciklopediyaPage && !isScrollToSite && infinityIndex !== 0 && !isGameComparePage) && (
        <>
          {hr}
          <Boroda />
          {!isPremiumInInfinity && hr}
        </>
      )}

      <ColumnWrapper
        rightColumn={<InnerSideColumn hideRnet={isEnciklopediyaPage} />}
      >
        {isPremiumInInfinity && (
          <Indent top={isMobile ? 50 : 100} bottom={20}>
            <PremiumTopicHeader
              content={content}
              isPremiumInInfinity
            />
          </Indent>
        )}
        <ContentWrapper isPremiumTopic={isPremiumTopic}>
          <TopicHeader
            content={content}
            isGameCompare={isGameComparePage}
            infinityIndex={infinityIndex}
          />
          {!isGameComparePage && (
            <Fragment>
              <TopicContent content={content} />
              {!isPremiumTopic && (
                <TopicFooter
                  content={content}
                  hideSuperFooter={isScrollToSite}
                />
              )}
            </Fragment>
          )}
        </ContentWrapper>
        {isGameComparePage && <GameCompare content={content} />}
      </ColumnWrapper>
      {isPremiumTopic && <Indent top={100} />}
    </PageIndent>
  );
}

function TopicPage(props) {
  const {
    isMobile,
    history,
    match,
    location,
    location: {
      pathname,
    },
  } = props;

  const isEnciklopediyaPage = isEnciklopediya(pathname, ENCIKLOPEDIYA_PATHNAME);
  const isGameComparePage = isGameCompare(pathname);

  const [
    { data: content, isLoading: contentAreLoading },
    { data: footerContent, isLoading: footerContentIsLoading },
  ] = useRequest({
    queries: [
      topicQuery({ location, match, history }),
      {
        ...footerQuery({ history }),
        enabled: !isGameCompare(props.location.pathname),
      },
    ],
  });

  if (contentAreLoading) return <TopicSkeleton />;

  const extendedTopicRenderer = (topic, extendedTopic) => topicRenderer(
    topic,
    extendedTopic,
    isMobile,
    isEnciklopediyaPage,
    isGameComparePage
  );

  if (isGameComparePage) {
    return (
      <Topic content={content}>
        {extendedTopicRenderer}
      </Topic>
    );
  }

  return (
    <PageBoroda content={footerContent} isLoading={footerContentIsLoading}>
      <InfiniteTopic
        content={content}
        {...!isEnciklopediyaPage && { rcmBlockId: resolve({
          '*': '18121a4f79694723ab61680f6db67665',
          'production': isMobile ? '84ee92f56ea44c3a8c92c2135f27c9fb' : '239dcd0c749b4f82ade2a92975d73ef9',
        }) }}
        {...isEnciklopediyaPage ?
          { rubric: ENCIKLOPEDIYA_SLUG } :
          { excluded_rubrics: ENCIKLOPEDIYA_SLUG }}
      >
        {extendedTopicRenderer}
      </InfiniteTopic>
    </PageBoroda>
  );
}

TopicPage.propTypes = {
  isMobile: PropTypes.bool,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
};

export default withBreakpoint(TopicPage);
