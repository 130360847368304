import useRequest from 'core/hooks/useRequest';
import footerQuery from 'site/queries/footer';

import CoreLegal from 'core/components/LegalPage';

import { PageBoroda } from 'site/components/Wrappers';


function LegalPage(routerParams) {
  const { data: footerContent, isLoading: footerContentIsLoading } = useRequest(footerQuery(routerParams));

  return (
    <PageBoroda content={footerContent} isLoading={footerContentIsLoading}>
      <CoreLegal />
    </PageBoroda>
  );
}

export default LegalPage;
