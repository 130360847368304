import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import footerQuery from 'site/queries/footer';

import CoreRulesRecommenders from 'core/components/RulesRecommenders';

import { PageBoroda } from 'site/components/Wrappers';


function RulesRecommenders({ mail, history }) {
  const { data: footerContent, isLoading: footerContentIsLoading } = useRequest(footerQuery({ history }));

  return (
    <PageBoroda content={footerContent} isLoading={footerContentIsLoading}>
      <CoreRulesRecommenders mail={mail} />
    </PageBoroda>
  );
}

RulesRecommenders.propTypes = {
  mail: PropTypes.string,
  history: PropTypes.object,
};

export default RulesRecommenders;
