import PropTypes from 'prop-types';
import cx from 'classnames';

import bindProps from 'core/components/bindProps';
import { withRouter } from 'core/libs/router';

import modelPropTypes, { personAttributes } from 'core/utils/prop-types/model';
import withTheme from 'core/components/theme';
import AuthorTypeLabel from 'core/components/AuthorTypeLabel';
import Link from 'core/components/Link';
import Avatar from 'site/components/Avatar';
import EmptyWrapper from 'core/components/EmptyWrapper';

import { getImageSrc } from 'site/utils.js';
import mapTypes from './types';

import styles from './index.styl';


const requiredPayloadFields = [
  'name',
  'slug',
  'job_title',
  'avatar',
  'type',
];

const sizes = {
  s: {
    imageMaxWidth: 60,
  },
  m: {
    imageMaxWidth: 100,
  },
};


function CardAuthor(props) {
  const {
    content,
    author,
    theme,
    type,
    size,
    location,
  } = props;

  if (!(content || author)) return null;

  const {
    vertical,
    topLabel,
  } = mapTypes[type] || {};

  const {
    imageMaxWidth,
  } = sizes[size] || {};

  const {
    attributes: {
      name,
      slug,
      job_title: jobTitle,
      avatar: avatarAttrs,
      type: typeAuthor,
    },
  } = content || author;

  const avatarSrc = getImageSrc(avatarAttrs);

  const isUgc = typeAuthor === 'ugc';
  const authorPageUrl = `/authors/${slug}`;
  const isAuthorPage = location.pathname === authorPageUrl;
  const Wrapper = isAuthorPage ? EmptyWrapper : Link;

  return (
    <Wrapper to={authorPageUrl} type='secondary'>
      <div
        className={cx(
          styles.container,
          vertical && styles._vertical,
          styles[`_size_${size}`],
        )}
      >
        <style jsx>{`
        .${styles.body}
          font 14px/17px ${theme.fonts.display}
          color ${theme.colors.primary}

        .${styles.divider}
          background-color ${theme.colors.divider}

        .${styles.name}
          font bold 32px/35px ${theme.fonts.display}
          .${styles._size_s} &
            font bold 16px/19px ${theme.fonts.display}
      `}</style>
        <div className={vertical && styles.avatar}>
          <Avatar
            src={avatarSrc}
            size={imageMaxWidth}
            isUgc={isUgc}
          />
        </div>

        <div className={styles.body}>
          {topLabel && (
            <div className={styles.label}>
              <AuthorTypeLabel type={typeAuthor} />
            </div>
          )}
          <div className={styles.nameWrapper}>
            <span className={styles.name}>{name}</span>
            {!topLabel && <AuthorTypeLabel type={typeAuthor} />}
          </div>
          {jobTitle && <div className={styles.job}>{jobTitle}</div>}
        </div>
      </div>
    </Wrapper>
  );
}

CardAuthor.propTypes = {
  /** Данные автора, соответствующие модели `person` */
  content: modelPropTypes(personAttributes),
  /** Данные автора, соответствующие модели `person` */
  author: modelPropTypes(personAttributes),
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1, 2]),
  /** Размер карточки */
  size: PropTypes.oneOf(['s', 'm']),
  /** @ignore */
  theme: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

CardAuthor.defaultProps = {
  type: 0,
  size: 'm',
};

const Card = withTheme(withRouter(CardAuthor));

Card.displayName = 'CardAuthor';
Card.requiredPayloadFields = requiredPayloadFields;

export const CardAuthorS = bindProps({ size: 's' })(Card);
export const CardAuthorVertical = bindProps({ type: 1, size: 's' })(Card);
export const CardAuthorTopLabel = bindProps({ type: 2 })(Card);

export default Card;

export { CardAuthor as StorybookComponent };
