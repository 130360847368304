import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import footerQuery from 'site/queries/footer';

import CoreProfile from 'core/components/ugc/Profile';

import { PageBoroda } from 'site/components/Wrappers';


function Profile({ history }) {
  const { data: footerContent, isLoading: footerContentIsLoading } = useRequest(footerQuery({ history }));

  return (
    <PageBoroda content={footerContent} isLoading={footerContentIsLoading}>
      <CoreProfile />
    </PageBoroda>
  );
}

Profile.propTypes = {
  history: PropTypes.object,
};

export default Profile;
