import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { rawTopicsQuery } from 'core/queries/rubric';
import footerQuery from 'site/queries/footer';

import ListPage from 'core/components/ListPage';
import ColumnLayout from 'core/components/ColumnLayout';
import PageLoader from 'core/components/Loader/PageLoader';

import { getPage } from 'core/utils/url-helper';
import { filterRequiredParams } from 'core/utils/api';

import { PageIndent } from 'core/components/Wrappers';
import InnerSideColumn from 'site/components/InnerSideColumn';
import RubricTopics from 'site/components/RubricTopics';
import Boroda from 'site/components/Boroda';
import ListHeader from 'site/components/ListHeader';
import { PageBoroda } from 'site/components/Wrappers';

import { RUBRICS_PAGE_LIMIT } from 'site/constants';

import { CardVertical1 } from 'site/cards/CardVertical';
import CardHorizontal from 'site/cards/CardHorizontal';


const title = 'Последние новости России и мира — все статьи и новости';
const description = 'Читайте свежие новости России на портале Секрет фирмы. Издание о бизнесе, экономике, финансах и высоких технологиях, которые обеспечивают развитие экономики и бизнеса в России и в мире';

const include = filterRequiredParams([CardVertical1, CardHorizontal], 'include');
const fields = filterRequiredParams([CardVertical1, CardHorizontal], 'fields');

function NewsPage(props) {
  const {
    meta,
    location,
    match,
    history,
  } = props;

  const offset = (getPage(location.search) - 1) * RUBRICS_PAGE_LIMIT;

  const { data: footerContent, isLoading: footerContentIsLoading } = useRequest(footerQuery({ history }));

  const { data: rawData, isLoading: topicsAreLoading } = useRequest(rawTopicsQuery({
    location,
    match,
    history,
    limit: RUBRICS_PAGE_LIMIT,
    offset,
    include,
    fields,
    topic_type: 'news',
    with_filtered_count: 1,
  }));

  return (
    <PageBoroda content={footerContent} isLoading={footerContentIsLoading}>
      <PageIndent>
        <ColumnLayout rightColumn={<InnerSideColumn />}>
          <ListHeader>Новости</ListHeader>
          {topicsAreLoading && <PageLoader />}
          {!topicsAreLoading &&
            <ListPage
              rawData={rawData}
              title={title}
              description={description}
              meta={meta}
              limit={RUBRICS_PAGE_LIMIT}
              isLoading={topicsAreLoading}
            >
              {({ content }) => <RubricTopics topics={content} />}
            </ListPage>
          }
          <Boroda />
        </ColumnLayout>
      </PageIndent>
    </PageBoroda>
  );
}

NewsPage.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  meta: PropTypes.shape({
    filtered_count: PropTypes.number,
  }),
};

export default NewsPage;
