import { topicsQuery } from 'core/queries/topics';

import { denormalizeData } from 'core/utils/api';

export default ({ history }) => {
  return {
    ...topicsQuery({
      history,
      limit: 7,
      include: 'image',
      list: 'video',
      with_filtered_count: 1,
      fields: ['headline', 'link', 'topic_type'],
    }),
    select: denormalizeData,
  };
};
