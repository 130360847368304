import PropTypes from 'prop-types';

import { rawRubricQuery, rawTopicsQuery } from 'core/queries/rubric';
import useRequest from 'core/hooks/useRequest';
import footerQuery from 'site/queries/footer';

import { filterRequiredParams } from 'core/utils/api';

import Rubric from 'core/components/Rubric';
import ColumnLayout from 'core/components/ColumnLayout';
import H5 from 'core/components/H5';
import { PageIndent } from 'core/components/Wrappers';
import PageLoader from 'core/components/Loader/PageLoader';

import InnerSideColumn from 'site/components/InnerSideColumn';
import RubricTopics from 'site/components/RubricTopics';
import Boroda from 'site/components/Boroda';
import ListHeader from 'site/components/ListHeader';
import { PageBoroda } from 'site/components/Wrappers';

import { RUBRICS_PAGE_LIMIT } from 'site/constants';

import { CardVertical1 } from 'site/cards/CardVertical';
import CardHorizontal from 'site/cards/CardHorizontal';
import CompareCard from 'site/cards/CompareCard';


function RubricPage({ location, match, history }) {
  const { data: footerContent, isLoading: footerContentIsLoading } = useRequest(footerQuery({ history }));

  const [
    { data: rawRubric, isLoading: rubricIsLoading },
    { data: rawTopics, isLoading: topicsAreLoading },
  ] = useRequest({
    queries: [
      rawRubricQuery({ match, history }),
      rawTopicsQuery({
        location,
        match,
        history,
        limit: RUBRICS_PAGE_LIMIT,
        fields: filterRequiredParams([CardVertical1, CardHorizontal, CompareCard], 'fields'),
        include: filterRequiredParams([CardVertical1, CardHorizontal, CompareCard], 'include'),
      }),
    ],
  });

  return (
    <PageBoroda content={footerContent} isLoading={footerContentIsLoading}>
      <PageIndent>
        <ColumnLayout
          rightColumn={(
            <InnerSideColumn />
          )}
        >
          {!rubricIsLoading &&
            <Rubric
              rawRubric={rawRubric}
              rawTopics={rawTopics}
              limit={RUBRICS_PAGE_LIMIT}
              titleComponent={ListHeader}
              isLoading={topicsAreLoading}
            >
              {({ topics }) => {
                if (topicsAreLoading) return <PageLoader />;
                if (!topics?.length) return <H5>Материалов по теме не найдено</H5>;

                return <RubricTopics topics={topics} />;
              }}
            </Rubric>
          }
          <Boroda />
        </ColumnLayout>
      </PageIndent>
    </PageBoroda>
  );
}

RubricPage.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default RubricPage;
