import { Fragment } from 'react';
import { Helmet } from 'core/libs/helmet';
import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { tagQuery, topicsQuery } from 'core/queries/tagTopics';
import footerQuery from 'site/queries/footer';

import { filterRequiredParams } from 'core/utils/api';

import TagTopics from 'core/components/TagTopics';
import ColumnLayout from 'core/components/ColumnLayout';
import PageLoader from 'core/components/Loader/PageLoader';
import H5 from 'core/components/H5';
import { PageIndent } from 'core/components/Wrappers';

import RubricTopics from 'site/components/RubricTopics';
import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';
import ListHeader from 'site/components/ListHeader';
import { PageBoroda } from 'site/components/Wrappers';

import { RUBRICS_PAGE_LIMIT } from 'site/constants';

import { CardVertical1 } from 'site/cards/CardVertical';
import CardHorizontal from 'site/cards/CardHorizontal';


const TagPage = ({ location, match, history }) => {
  const { data: footerContent, isLoading: footerContentIsLoading } = useRequest(footerQuery({ history }));

  const [
    { data: tag, isLoading: tagIsLoading },
    { data: tagTopics, isLoading: tagTopicsIsLoading },
  ] = useRequest({
    queries: [
      tagQuery({ history, match }),
      topicsQuery({
        location,
        match,
        history,
        limit: RUBRICS_PAGE_LIMIT,
        include: filterRequiredParams([CardVertical1, CardHorizontal], 'include'),
        fields: filterRequiredParams([CardVertical1, CardHorizontal], 'fields'),
      }),
    ],
  });

  return (
    <PageBoroda content={footerContent} isLoading={footerContentIsLoading}>
      <PageIndent>
        <ColumnLayout rightColumn={<InnerSideColumn />}>
          {tagIsLoading && <PageLoader />}
          {!tagIsLoading &&
            <TagTopics
              tag={tag}
              topics={tagTopics}
              limit={RUBRICS_PAGE_LIMIT}
              titleComponent={ListHeader}
              isLoading={tagIsLoading}
            >
              {({ title, titleMeta, descriptionMeta, topics }) => {
                if (tagTopicsIsLoading) return <PageLoader />;
                if (!topics?.length) return <H5>Материалов по теме не найдено</H5>;

                return (
                  <Fragment>
                    <Helmet>
                      <title>{titleMeta || `#${title}: новости, кейсы, статьи`}</title>
                      <meta name='description' content={descriptionMeta || `Новости, кейсы, статьи по тегу #${title} в интернет-журнале Секрет фирмы.`} />
                    </Helmet>
                    <RubricTopics topics={topics} />
                  </Fragment>
                );
              }}
            </TagTopics>
          }
          <Boroda />
        </ColumnLayout>
      </PageIndent>
    </PageBoroda>
  );
};

TagPage.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default TagPage;
