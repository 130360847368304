import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import Link from 'core/components/Link';
import Lightning from 'core/components/GameCompare/Lightning';

import CardInfoLine from 'site/components/CardInfoLine';
import HoverFrame from 'site/components/HoverFrame';

import styles from './index.styl';


const requiredPayloadFields = [
  'link',
  'headline',
  'listHeadline',
  'published_at',
];

const requiredPayloadImports = [
  'content',
  'rubric',
];
const relationships = resolveRelationships(requiredPayloadImports, null, {
  content: {},
});


function CompareCard(props) {
  const {
    content,
    theme: {
      fonts,
      colors,
    },
    isMobile,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    listHeadline,
    published_at: publishedAt,
  } = content.attributes;

  const {
    content: {
      widgets,
    },
    rubric,
  } = relationships(content);

  if (!widgets || widgets?.length === 0) return null;

  const {
    first_announce: firstAnnounce,
  } = widgets[0]?.attributes || {};

  return (
    <Link
      className={styles.card}
      to={link}
      type='secondary'
    >
      <style jsx>{`
        .${styles.wrapper}
          border 1px solid ${colors.divider}
          :global(.mobile) &
            border-top none
            border-left none
            border-right none

        .${styles.wrapper}
          background-color ${colors.layout}

        .headline
          font 700 24px/26px ${fonts.display}
          color ${colors.active1}
          :global(.mobile) &
            font 700 18px/21px ${fonts.display}

        .${styles.date}
          font 11px/14px ${fonts.text}
          color ${colors.credits}
  
        .${styles.firstAnnounce}
          font 400 16px/20px ${fonts.text}
          color ${colors.primary}
      `}</style>
      <HoverFrame>
        <div className={styles.wrapper}>
          <Lightning
            imageMaxWidth={isMobile ? 550 : 360}
            height={215}
            widget={widgets[0]}
            progressType={4}
            lightningColor={colors.black}
            borderRadius='0'
          />
          <div className={styles.body}>
            {(listHeadline || headline) && (
              <div className='headline'>
                {listHeadline || headline}
              </div>
            )}
            {firstAnnounce && <div className={styles.firstAnnounce}>{firstAnnounce}</div>}
            <div className={styles.date}>
              <CardInfoLine
                rubric={rubric}
                publishedAt={publishedAt}
              />
            </div>
          </div>
        </div>
      </HoverFrame>
    </Link>
  );
}

CompareCard.propTypes = {
  /** Контент топика */
  content: modelPropTypes(topicAttributes),
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
};

const Card = withTheme(withBreakpoint(CompareCard));

Card.displayName = 'CompareCard';
Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export default Card;

export { CompareCard as StorybookComponent };
