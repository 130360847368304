import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { debounce } from 'core/libs/lodash';

import queryString from 'core/libs/query-string';
import Position from 'core/components/GridPosition';
import H2 from 'core/components/H2';

import { bebop as bebopApi } from 'core/api/definitions/bebop';

import useRequest from 'core/hooks/useRequest';
import { topicsQuery } from 'core/queries/topics';
import { rawTopicsQuery } from 'core/queries/listPage';
import footerQuery from 'site/queries/footer';

import { getPage } from 'core/utils/url-helper';
import { filterRequiredParams } from 'core/utils/api';

import InnerSideColumn from 'site/components/InnerSideColumn';
import Divider from 'core/components/Divider';
import { Block, Section } from 'core/components/Grid';
import SearchInput from 'core/components/SearchInput';
import ListPage from 'core/components/ListPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent, Indent } from 'core/components/Wrappers';

import EnciklopediyaDescription from 'site/components/EnciklopediyaDescription';
import { PageBoroda } from 'site/components/Wrappers';

import CardEnciklopedy from 'site/cards/CardEnciklopedy';
import CardSmall  from 'site/cards/CardSmall';

import { ENCIKLOPEDIYA_PAGE_LIMIT } from 'site/constants';

import Topics from './Topics';
import Tags from './Tags';


const title = 'Объясняем простыми словами сложные вещи — все статьи и новости';
const description = 'Энциклопедия российского интернет-журнала об экономике, финансах и высоких технологиях. Секрет фирмы объясняет простыми словами сложные термины, рассказывает про ключевые фигуры в политике и мире бизнеса';

const include = filterRequiredParams([CardEnciklopedy], 'include', ['tags']);

function EnciklopediyaPage({ location, history }) {
  const offset = (getPage(location.search) - 1) * ENCIKLOPEDIYA_PAGE_LIMIT;
  const queryFilters = queryString.parse(location.search);
  const searchTag = queryFilters.search || '';

  const [searchedTopics, updateSearchedTopics] = useState();
  const [searchValue, updateSearchValue] = useState();

  const [
    { data: topics, isLoading: topicsIsLoading },
    { data: news, isLoading: newsIsLoading },
    { data: footerContent, isLoading: footerContentIsLoading },
  ] = useRequest({
    queries: [
      rawTopicsQuery({
        limit: ENCIKLOPEDIYA_PAGE_LIMIT,
        include,
        offset,
        fields: filterRequiredParams([CardEnciklopedy], 'fields'),
        rubric: 'enciklopediya',
        tag: searchTag,
        with_filtered_count: 1,
        sort: 'list_headline,headline',
        history,
        location,
      }),
      topicsQuery({
        fields: filterRequiredParams([CardSmall], 'fields'),
        rubric: 'practice,zhizn',
        limit: 7,
        history,
      }),
      footerQuery({ history }),
    ],
  });

  const { data: searchContent, isLoading: searchContentIsLoading, refetch: refetchSearch } = useRequest({
    queryKey: ['bebopApi', 'getSearch', {
      query: searchValue,
      rubric: 'enciklopediya',
      limit: ENCIKLOPEDIYA_PAGE_LIMIT,
      offset,
      title_only: 1,
      include,
      location,
    }],
    queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
    enabled: !!searchValue,
    select: data => ({
      ...data,
      meta: {
        filtered_count: data?.meta?.search_results.total_found,
      },
    }),
  });

  const searchValueChanged = useMemo(() =>
    debounce(value => {
      updateSearchValue(value);
    }, 500)
  , [updateSearchValue]);

  const handleSearchInput = event => {
    searchValueChanged(event.target.value);
  };

  useEffect(() => {
    if (!searchValue) {
      updateSearchedTopics(null);
    } else {
      refetchSearch();
    }
  }, [searchValue, refetchSearch]);

  useEffect(() => {
    if (searchContentIsLoading) return;
    updateSearchedTopics(searchContent);
  }, [searchContent, searchContentIsLoading]);

  const {
    filtered_count: filteredCount,
  } = searchedTopics?.meta || topics?.meta || {};

  return (
    <PageBoroda content={footerContent} isLoading={footerContentIsLoading}>
      <PageIndent>
        <ColumnLayout rightColumn={<InnerSideColumn hideRnet />}>
          <EnciklopediyaDescription />
          <Indent bottom={40} />
          <Tags disabled={!!searchValue} />
          <Divider />
          <Indent bottom={40} />
          <Section>
            <Block desktop={2} mobile={0} />
            <Block desktop={9} mobile={12} >
              <SearchInput
                placeholder='Поиск термина'
                onInput={handleSearchInput}
                value={searchValue}
              />
            </Block>
            <Block />
          </Section>
          <Indent bottom={40} />
          {filteredCount > 0 ? (
            <ListPage
              rawData={searchedTopics || topics}
              title={title}
              description={description}
              limit={ENCIKLOPEDIYA_PAGE_LIMIT}
              isLoading={newsIsLoading || topicsIsLoading}
            >
              {({ content }) => <Topics topics={content} news={news} />}
            </ListPage>
          ) : (
            <Position center><H2>Материалов не найдено</H2></Position>
          )}
        </ColumnLayout>
      </PageIndent>
    </PageBoroda>
  );
}

EnciklopediyaPage.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

export default EnciklopediyaPage;
