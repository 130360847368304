import PropTypes from 'prop-types';
import cx from 'classnames';
import { withRouter } from 'core/libs/router';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import bindProps from 'core/components/bindProps';
import withTheme from 'core/components/theme';

import EmptyWrapper from 'core/components/EmptyWrapper';
import Link from 'core/components/Link';
import SmartImage from 'core/components/SmartImage';
import AdvTooltip from 'core/components/AdvTooltip';

import CardInfoLine from 'site/components/CardInfoLine';
import HoverFrame from 'site/components/HoverFrame';

import IconVideo from 'site/icons/Video';
import IconCards from 'site/icons/Cards';

import UgcLabel from 'site/components/UgcLabel';

import types from './types';
import styles from './index.styl';

const requiredPayloadImports = [
  'image',
  'rubric',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'alternative_headline',
  'topic_type',
  'published_at',
  'ad_label',
  'link_with_announce_erid',
  'advertiser_name',
];

const relationships = resolveRelationships(requiredPayloadImports, null, {
  image: {
    versions: {},
  },
});

const iconsMap = {
  video: IconVideo,
  card: IconCards,
};

const imageMaxWidth = 500; // keep in sync with the same var in .styl!


function CardVertical(props) {
  const {
    content,
    type,
    size,
    theme,
    location,
    position,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    alternative_headline: altHeadline,
    topic_type: topicType,
    published_at: publishedAt,
    ad_label: adLabel,
    link_with_announce_erid: linkWithAnnounceErid,
    advertiser_name: advertiserName,
  } = content.attributes;

  const {
    image: {
      versions: {
        original: originalCover,
        thumbnail: previewCover,
      },
    },
    rubric,
  } = relationships(content);

  const {
    link_attrs: linkAttrs,
    remote_image: remoteImage,
  } = content.extra || {};

  const {
    accented,
    imageUnderlined,
    imageRatio,
  } = types[type] || {};

  const isMainPage = location.pathname === '/';
  const isSpecproject = topicType === 'specproject';
  const isUgc = topicType === 'ugc';
  const hasImage = !!(originalCover || remoteImage);
  const Icon = iconsMap[topicType];
  const FrameWrapper = accented ? EmptyWrapper : HoverFrame;
  const shouldShowAdvTooltip = !!(isMainPage && position === 1 && linkWithAnnounceErid && advertiserName && adLabel);
  const topicLink = shouldShowAdvTooltip ? linkWithAnnounceErid : link;

  return (
    <div
      className={cx(
        styles.card,
        styles[`_${topicType}`],
        styles[`_size_${size}`],
        !hasImage && styles._noImage,
        accented && styles._accented,
        imageUnderlined && styles._imageUnderlined,
      )}
    >
      <style jsx>{`
        .${styles.image}:after
          background ${theme.colors.yellow}

        .${styles.content}
          color ${theme.colors.primary}
          .${styles._accented} &
            background ${theme.colors.yellow}
            color ${theme.colors.text}

        .${styles.headline}
          font-family ${theme.fonts.display}

        .${styles.altHeadline}
          font-family ${theme.fonts.text}
      `}</style>
      <Link
        to={topicLink}
        type='secondary'
        className={styles.link}
        {...linkAttrs && {
          innerRef: node => {
            if (node) {
              linkAttrs.forEach(({ name, value }) => node.setAttribute(name, value));
            }
          },
        }}
        {...isSpecproject && { target: '_blank' }}
      >
        {hasImage && (
          <div className={styles.imagePositioner}>
            <FrameWrapper triggerClassName={styles.link}>
              <div className={styles.image}>
                <SmartImage
                  {...originalCover && {
                    src: originalCover,
                    previewSrc: previewCover,
                  }}
                  {...remoteImage && { url: remoteImage }}
                  aspectRatio={imageRatio}
                  maxWidth={imageMaxWidth}
                />
                {isUgc && <UgcLabel className={styles.ugcLabel} />}
              </div>
            </FrameWrapper>
            {Icon && (
              <div className={styles.iconWrapper}>
                <Icon
                  width={19}
                  height={19}
                />
              </div>
            )}
          </div>
        )}
        <div className={styles.content}>
          <div className={styles.headline}>
            {listHeadline || headline}
          </div>
          {altHeadline && (
            <div className={styles.altHeadline}>
              {altHeadline}
            </div>
          )}
          <div className={styles.infoLine}>
            <CardInfoLine
              rubric={rubric}
              publishedAt={publishedAt}
              {...accented && { color: theme.colors.hint }}
            />
            {isUgc && !hasImage &&
              <div className={styles.ugcLabelCircle}>
                <UgcLabel circle />
              </div>
            }
          </div>
        </div>
      </Link>
      {shouldShowAdvTooltip && (
        <AdvTooltip
          text={advertiserName}
          adLabel={adLabel}
        />
      )}
    </div>
  );
}

CardVertical.propTypes = {
  /** Контент топика */
  content: modelPropTypes(topicAttributes),
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1, 2]),
  /** Размер карточки */
  size: PropTypes.oneOf(['s', 'm', 'l']),
  /** @ignore */
  theme: PropTypes.object.isRequired,
  /** @ignore */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  position: PropTypes.number,
};

CardVertical.defaultProps = {
  type: 0,
  size: 'm',
};

const Card = withTheme(withRouter(CardVertical));

Card.displayName = 'CardVertical';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export { Card as CardVerticalM };
export { Card as CardVertical0M };
export const CardVerticalS = bindProps({ size: 's' })(Card);
export const CardVerticalL = bindProps({ size: 'l' })(Card);
export const CardVertical1 = bindProps({ type: 1 })(Card);
export const CardVertical1S = bindProps({ type: 1, size: 's' })(Card);
export const CardVertical1M = bindProps({ type: 1, size: 'm' })(Card);
export const CardVertical1L = bindProps({ type: 1, size: 'l' })(Card);
export const CardVertical2L = bindProps({ type: 2, size: 'l' })(Card);

export default Card;

export { CardVertical as StorybookComponent };
