import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { authorQuery, topicsQuery } from 'core/queries/authorPage';
import footerQuery from 'site/queries/footer';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import { PageIndent } from 'core/components/Wrappers';
import AuthorPage from 'core/components/AuthorPage';
import ColumnLayout from 'core/components/ColumnLayout';
import PageLoader from 'core/components/Loader/PageLoader';

import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';
import { PageBoroda } from 'site/components/Wrappers';

import AuthorPageCard from './AuthorPageCard';
import { CardVertical1 } from 'site/cards/CardVertical';
import CardHorizontal from 'site/cards/CardHorizontal';

const descriptionBuilder = author => {
  return (author?.attributes?.name ? author.attributes.name + ', ' : '') + (author?.attributes?.job_title || '') + ' - авторы и эксперты интернет-журнала Секрет Фирмы';
};

function Author({ isMobile, location, match, history }) {
  const [
    { data: author, isLoading: authorIsLoading },
    { data: topics, isLoading: topicsAreLoading },
    { data: footerContent, isLoading: footerContentIsLoading },
  ] = useRequest({
    queries: [
      authorQuery({ match, history }),
      topicsQuery({
        location,
        match,
        limit: 15,
        include: 'rubric,image',
      }),
      footerQuery({ history }),
    ],
  });

  return (
    <PageBoroda content={footerContent} isLoading={footerContentIsLoading}>
      <PageIndent>
        <ColumnLayout rightColumn={<InnerSideColumn />}>
          {authorIsLoading && <PageLoader />}
          {!authorIsLoading &&
            <AuthorPage
              author={author}
              topics={topics}
              card={isMobile ? CardVertical1 : CardHorizontal}
              interitemSpacing={20}
              authorCard={AuthorPageCard}
              descriptionBuilder={descriptionBuilder}
              isLoading={topicsAreLoading}
            />
          }
        </ColumnLayout>
      </PageIndent>
      <Boroda />
    </PageBoroda>
  );
}

Author.propTypes = {
  isMobile: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default withBreakpoint(Author);
