import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';

import { withBreakpoint } from 'core/components/breakpoint';
import bindProps from 'core/components/bindProps';

import RecommendationWidget from 'core/components/RecommendationWidget';

import ListHeader from 'site/components/ListHeader';

import { hasPreviewToken } from 'core/utils/url-helper';


function SiteRecommender({ location, isMobile }) {
  if (hasPreviewToken(location.search)) return null;

  return (
    <RecommendationWidget
      blockId={isMobile ? '0be61d92c1c842948532f10d294ffd34' : '39985572aff04377bfbb8f555c69b391'}
      title='Популярное'
      headerComponent={bindProps({ level: 2, bottomIndent: 25 })(ListHeader)}
    />
  );
}

SiteRecommender.propTypes = {
  isMobile: PropTypes.bool,
  location: PropTypes.object,
};

export default withBreakpoint(withRouter(SiteRecommender));
