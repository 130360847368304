import { Fragment } from 'react';
import PropTypes from 'prop-types';

import withMenu from 'core/components/withMenu';
import withTheme from 'core/components/theme';
import { withNonPureBreakpoint } from 'core/components/breakpoint';

import Shapka from 'core/components/Shapka';

import { menuPropTypes } from 'core/utils/prop-types/relationships';

import styles from './index.styl';


const SEARCH_PATH = '/search';

function Shlyapa(props) {
  const {
    menu,
    isMobile,
    theme,
    isSecondShapkaForPremium,
  } = props;

  let modifiedMenu = menu;

  if (isMobile) {
    const menuItems = menu.data.attributes.menu_items.filter(({ link }) => link !== SEARCH_PATH);

    modifiedMenu = {
      data: {
        ...menu.data,
        attributes: {
          ...menu.attributes,
          menu_items: menuItems,
        },
      },
    };
  }

  return (
    <Fragment>
      <style jsx>{`
        .${styles.topLine}
          background-color ${theme.colors.text}
      `}</style>
      {(!isMobile && !isSecondShapkaForPremium) && <div className={styles.topLine} />}
      <Shapka
        fullwidth
        menu={modifiedMenu}
        isSecondShapkaForPremium={isSecondShapkaForPremium}
      />
    </Fragment>
  );
}

Shlyapa.propTypes = {
  /**
    * В премиум топике 2 шапки, вторая отображается после скролла обложки
  */
  isSecondShapkaForPremium: PropTypes.bool,
  isMobile: PropTypes.bool,
  menu: menuPropTypes(),
  theme: PropTypes.object,
};

export default withMenu('main')(withNonPureBreakpoint(withTheme(Shlyapa)));
