import PropTypes from 'prop-types';
import cx from 'classnames';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import withTheme from 'core/components/theme';
import bindProps from 'core/components/bindProps';

import Link from 'core/components/Link';
import SmartImage from 'core/components/SmartImage';

import CardInfoLine from 'site/components/CardInfoLine';
import { Indent } from 'site/components/Wrappers';
import Label from 'site/components/Label';

import IconVideo from 'site/icons/Video';
import IconCards from 'site/icons/Cards';

import UgcLabel from 'site/components/UgcLabel';

import { isTopicImportant } from 'site/utils';

import types from './types';
import styles from './index.styl';


const requiredPayloadFields = [
  'link',
  'headline',
  'published_at',
  'topic_type',
  'list_headline',
];

const requiredPayloadImports = ['image', 'rubric'];

const relationships = resolveRelationships(['image', 'rubric'], null, {
  image: {
    versions: {},
  },
});

const iconsMap = {
  video: IconVideo,
  card: IconCards,
};


function CardSmall(props) {
  const {
    theme,
    content,
    type,
    utm,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    topic_type: topicType,
    published_at: publishedAt,
  } = content.attributes;

  const {
    remote_image: remoteImage,
    link_attrs: linkAttrs,
  } = content.extra || {};

  const {
    rubricAsLabel,
    rubricLabelAtRight,
    rubricLabelAtTop,
  } = types[type] || {};

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
    rubric,
  } = relationships(content);

  const isSpecproject = topicType === 'specproject';
  const isUgc = topicType === 'ugc';
  const isImportant = isTopicImportant(content);
  const Icon = iconsMap[topicType];
  const to = utm ? link + utm : link;

  return (
    <div
      className={cx(
        styles.container,
        styles[`_${topicType}`],
        rubricLabelAtTop && styles._rubricLabelAtTop,
      )}
    >
      <style jsx>{`
        .${styles.headline}
          font 400 16px/19px ${theme.fonts.display}
          color ${theme.colors.primary}
        `}
      </style>
      <Link
        to={to}
        type='secondary'
        {...linkAttrs && {
          innerRef: node => {
            if (node) {
              linkAttrs.forEach(({ name, value }) => node.setAttribute(name, value));
            }
          },
        }}
        {...isSpecproject && { target: '_blank' }}
      >
        <div className={styles.wrapper}>
          <div className={styles.title}>
            {rubricLabelAtTop && (
              <Label rubric={rubric} isImportant={isImportant} />
            )}
            <div className={styles.headline}>
              {listHeadline || headline}
            </div>
            <Indent top={8} />
            <CardInfoLine
              publishedAt={publishedAt}
              rubricAsLabel={rubricAsLabel}
              rubricLabelAtRight={rubricLabelAtRight}
              {...!rubricLabelAtTop && { rubric }}
            />
          </div>
          {(cover || remoteImage) && (
            <div className={styles.imgWrapper}>
              <div className={styles.img}>
                <SmartImage
                  width={60}
                  src={cover}
                  previewSrc={previewCover}
                  url={remoteImage}
                  aspectRatio={1}
                />
                {isUgc && <UgcLabel className={styles.ugcLabel} />}
              </div>
              {Icon && (
                <div className={styles.iconWrapper}>
                  <Icon
                    className={styles.icon}
                    width={15}
                    height={15}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </Link>
    </div>
  );
}

CardSmall.propTypes = {
  /** Контент топика */
  content: modelPropTypes(topicAttributes),
  /** @ignore */
  theme: PropTypes.object,
  /** UTM-метка */
  utm: PropTypes.string,
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1, 2, 3]),
};

CardSmall.defaultProps = {
  type: 0,
};

const Card = withTheme(CardSmall);

Card.displayName = 'CardSmall';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export const CardSmall1 = bindProps({ type: 1 })(Card);
export const CardSmall2 = bindProps({ type: 2 })(Card);
export const CardSmall3 = bindProps({ type: 3 })(Card);

export default Card;

export { CardSmall as StorybookComponent };
