import { Fragment } from 'react';
import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { topicQuery } from 'core/queries/topic';
import footerQuery from 'site/queries/footer';

import Topic from 'core/components/Topic';
import ColumnLayout from 'core/components/ColumnLayout';
import Link from 'core/components/Link';
import Button from 'core/components/Button';
import Comments from 'core/components/Comments';
import PageLoader from 'core/components/Loader/PageLoader';

import InnerSideColumn from 'site/components/InnerSideColumn';
import { Indent, PageIndent } from 'core/components/Wrappers';
import Boroda from 'site/components/Boroda';
import { PageBoroda } from 'site/components/Wrappers';

import TopicHeader from 'site/pages/topic/TopicHeader';


function TopicPage({ location, match, history }) {
  const [
    { data: content, isLoading: contentAreLoading },
    { data: footerContent, isLoading: footerContentIsLoading },
  ] = useRequest({
    queries: [
      topicQuery({ location, match, history }),
      footerQuery({ history }),
    ],
  });

  if (contentAreLoading) return <PageLoader />;

  return (
    <PageBoroda content={footerContent} isLoading={footerContentIsLoading}>
      <PageIndent>
        <ColumnLayout rightColumn={<InnerSideColumn />}>
          <Topic content={content}>
            {topic => {
              const {
                id,
                attributes: {
                  link,
                  is_comments_disabled: isCommentsDisabled,
                },
              } = topic;

              return (
                <Fragment>
                  <TopicHeader
                    content={topic}
                    hideCommentsButton
                    hideMedia
                  />
                  <Indent bottom= {20} />
                  <Link to={link} >
                    <Button size='small'>Вернуться к статье</Button>
                  </Link>
                  <Comments xid={id} isCommentsDisabled={isCommentsDisabled} />
                </Fragment>
              );
            }}
          </Topic>
          <Boroda />
        </ColumnLayout>
      </PageIndent>
    </PageBoroda>
  );
}

TopicPage.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default TopicPage;
